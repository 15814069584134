import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
  static targets = ["saveButton", "form", "bookList", "deletedBooks", "saveButtonText", "saveButtonSpinner"]
  static values = { userListId: String }

  connect() {
    console.log("UserListSortController connected!! this.element: ", this.element)
    this.sortable = Sortable.create(this.bookListTarget, {
      animation: 150,
      handle: ".list-item", // This makes the whole book container draggable
      ghostClass: "sortable-ghost", // Add this line
      onEnd: this.updateOrder.bind(this)
    })
    this.originalOrder = this.getCurrentOrder()
    this.deletedBooks = new Set()
    this.formTarget.addEventListener('submit', this.handleSubmit.bind(this))
    this.hasUnsavedChanges = false
    window.addEventListener('beforeunload', this.handleBeforeUnload.bind(this))
  }

  disconnect() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload.bind(this))
  }

  getCurrentOrder() {
    const bookItems = Array.from(this.bookListTarget.children).filter(el => el.hasAttribute('data-id'))
    return bookItems.map(el => el.dataset.id)
  }

  updateOrder() {
    const newOrder = this.getCurrentOrder()
    this.hasUnsavedChanges = JSON.stringify(newOrder) !== JSON.stringify(this.originalOrder) || this.deletedBooks.size > 0
    this.saveButtonTarget.disabled = !this.hasUnsavedChanges
    this.updateNumbers()
  }

  updateNumbers() {
    // Only select elements that have data-id attribute (book items)
    const bookItems = Array.from(this.bookListTarget.children).filter(el => el.hasAttribute('data-id'))
    bookItems.forEach((el, index) => {
        const rankNumber = el.querySelector('.rank-number span')
        if (rankNumber) {
            rankNumber.textContent = index + 1
        }
    })
  }

  removeBook(event) {
    event.preventDefault()
    const bookItem = event.target.closest('[data-id]')
    const bookId = bookItem.dataset.id
    this.deletedBooks.add(bookId)
    bookItem.remove()
    this.updateOrder()
    this.deletedBooksTarget.value = Array.from(this.deletedBooks).join(',')
  }

  handleSubmit(event) {
    event.preventDefault()
    this.disableSaveButton()
    
    const formData = new FormData(this.formTarget)
    formData.append('new_order', this.getCurrentOrder().join(','))
    formData.append('deleted_books', Array.from(this.deletedBooks).join(','))

    fetch(this.formTarget.action, {
      method: 'POST',
      body: formData,
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      }
    }).then(response => {
      if (response.ok) {
        this.hasUnsavedChanges = false
        window.location.href = response.url // Redirect to the updated page
      } else {
        console.error('Failed to save changes')
        this.enableSaveButton()
      }
    }).catch(error => {
      console.error('Error:', error)
      this.enableSaveButton()
    })
  }

  disableSaveButton() {
    this.saveButtonTarget.disabled = true
    this.saveButtonTextTarget.textContent = "Saving..."
    this.saveButtonSpinnerTarget.classList.remove('d-none')
  }

  enableSaveButton() {
    this.saveButtonTarget.disabled = false
    this.saveButtonTextTarget.textContent = "Save Changes"
    this.saveButtonSpinnerTarget.classList.add('d-none')
  }

  handleBeforeUnload(event) {
    if (this.hasUnsavedChanges) {
      event.preventDefault()
      event.returnValue = ''
    }
  }

  moveToTop(event) {
    event.preventDefault()
    const bookItem = event.target.closest('[data-id]')
    this.bookListTarget.prepend(bookItem)
    this.updateOrder()
  }

  moveToBottom(event) {
    event.preventDefault()
    const bookItem = event.target.closest('[data-id]')
    this.bookListTarget.appendChild(bookItem)
    this.updateOrder()
  }
}
