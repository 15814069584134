// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import "@popperjs/core";
import * as bootstrap from "bootstrap"
import "./font-awesome/fontawesome"
import "./font-awesome/brands"
import "./font-awesome/solid"
import "promise-polyfill/src/polyfill";
import "whatwg-fetch"
import AutoCompleteJS from "@tarekraafat/autocomplete.js"
// initDeleteLinks();
import "./reviews";
import "./forms";
import "./default-autocomplete-categories"
import "./default-autocomplete-books"
// import "./admin-autocomplete-categories";
// import "./admin-autocomplete-books"
// import "./admin-autocomplete-olworks"
import initBookDateFilters from "./book-filter-dates"
// import "trix"
// import "@rails/actiontext"
import { initFirebaseApp, addAuthClickEvents, loginWithEmailLink, initAuthEvents, signOut, sendEmailLoginLink, loginUserWithEmailAndPwd, createUserWithEmailAndPwd, fetchCsrfToken, fetchCsrfTokenHappened, resetCsrfTokenFlag, getCsrfTokenStatus } from "./authentication";
import { initializeTooltips } from "./tooltips"
import { insertAds, handleAdsAfterResize, adjustSideRailAdsZIndex } from "./ads"
import { initUserBookLists } from "./user_lists"
import "./books-show"
import AirDatepicker from 'air-datepicker'
import 'air-datepicker/air-datepicker.css'
import localeEn from 'air-datepicker/locale/en'

function initDatePickers() {
  // Regular datepickers (for reading goals form)
  document.querySelectorAll('.datepicker:not(.modal-datepicker)').forEach(element => {
    console.log('shanewtf initDatePickers', element)
    new AirDatepicker(element, {
      locale: localeEn,
      dateFormat: 'M-dd-yyyy',  // Rails friendly format
      autoClose: true,
      buttons: ['today', 'clear']
    })
  })

  // Modal datepickers (for user list books)
  document.querySelectorAll('.modal-datepicker').forEach(element => {
    console.log('shanewtf initModalDatePickers', element)
    const modalBody = element.closest('.modal-body')
    
    new AirDatepicker(element, {
      locale: localeEn,
      dateFormat: 'M-dd-yyyy',
      autoClose: true,
      position: 'bottom left',
      container: modalBody,
      buttons: ['today', 'clear']
    })
  })
}

document.addEventListener('turbo:load', () => {
  console.log('turbo:load executed')
  // This will be executed every time a new page is fully loaded with Turbo Frames.
  // resetCsrfTokenFlag();
  initializeTooltips();
  initDatePickers
});

document.addEventListener('DOMContentLoaded', function () {

  initDatePickers();
  initializeTooltips();
  initBookDateFilters('date-start', 'date-end');
  initBookDateFilters('date-start-modal', 'date-end-modal');

  initUserBookLists();
  
  window.selectedBookId = null;  // Declare a global variable
  // Attach an event listener to a parent element
  document.body.addEventListener('click', function(event) {
    // Check if the clicked element has the class you're interested in
    if (event.target.classList.contains('new-custom-user-list-button')) {
      // Update the global variable with the value in the data-book attribute
      window.selectedBookId = event.target.dataset.book;
      console.log(`Selected Book ID: ${window.selectedBookId}`);
    }
  });
  
  insertAds();
  window.addEventListener('resize', handleAdsAfterResize);
  setTimeout(adjustSideRailAdsZIndex, 2000);
  // Adjust z-index immediately on .dropdown-toggle click
  document.querySelectorAll('.dropdown-toggle').forEach(item => {
    item.addEventListener('click', adjustSideRailAdsZIndex);
  });
});

const app = initFirebaseApp();
initAuthEvents(app);
document.addEventListener('turbo:frame-load', () => {
  console.log('turbo loaded! add auth click events')
  setTimeout(function() {
    initializeTooltips();
  }, 1000);
  addAuthClickEvents(app);
  initDatePickers();
});

document.addEventListener('turbo:frame-render', () => {
  console.log('turbo:frame-render! add auth click events')
  initializeTooltips();
  addAuthClickEvents(app);
  initDatePickers();
});


window.createUserWithEmailAndPwd = createUserWithEmailAndPwd;
window.loginUserWithEmailAndPwd = loginUserWithEmailAndPwd;
window.sendEmailLoginLink = sendEmailLoginLink;
window.loginWithEmailLink = loginWithEmailLink;

